import { handleActions } from 'redux-actions'

import fetch from '../services/fetch'
import _ from 'lodash'
import logger from '../services/errorlogger'
import history from '../history'

import { actions as JobsActions } from './JobsReducer'
import { actions as NotificationsActions } from '../modules/Notifications/reducer'
import { actions as ModalsActions } from './GlobalModalsReducer'
import { actions as SettingsActions } from './SettingsReducer'

export const actionTypes = {
  FETCH_SINGLE_SITE_LOADING: 'FETCH_SINGLE_SITE_LOADING',
  FETCH_SINGLE_SITE_DONE: 'FETCH_SINGLE_SITE_DONE',

  FETCH_ALL_SITES_LOADING: 'FETCH_ALL_SITES_LOADING',
  FETCH_ALL_SITES_DONE: 'FETCH_ALL_SITES_DONE',

  SET_SELECTED_SITE_ID: 'SET_SELECTED_SITE_ID',

  FETCH_SITEMAP_LOADING: 'FETCH_SITEMAP_LOADING',
  FETCH_SITEMAP_DONE: 'FETCH_SITEMAP_DONE',
  FETCH_SITEMAP_ERROR: 'FETCH_SITEMAP_ERROR',

  UPDATE_SITE_LOADING: 'UPDATE_SITE_LOADING',
  UPDATE_SITE_DONE: 'UPDATE_SITE_DONE',
  UPDATE_SITE_ERROR: 'UPDATE_SITE_ERROR',

  SIMPLE_CREATE_SITE_LOADING: 'SIMPLE_CREATE_SITE_LOADING',
  SIMPLE_CREATE_SITE_DONE: 'SIMPLE_CREATE_SITE_DONE',
  SIMPLE_CREATE_SITE_ERROR: 'SIMPLE_CREATE_SITE_ERROR',
}

export const actions = {
  addSite:
    (siteUrl = '', topUserId, siteProperties = {}) =>
    async (dispatch, getState) => {
      try {
        const { settings, sites } = getState()
        const allowedProjects =
          settings && settings.permissions && settings.permissions.allowedProjects
        const hasPurchased = settings && settings.permissions && settings.permissions.hasPurchased
        const canCreateProjects = allowedProjects
          ? allowedProjects > sites.sites.filter((s) => s.isSetupFinished).length
          : false
        // debugger;
        // if (hasPurchased && !canCreateProjects) {
        if (!siteProperties.isFirstSite && !canCreateProjects) {
          dispatch(SettingsActions.notifyUserSiteLimit())
          return dispatch(ModalsActions.toggleUpgradeModal('projects'))
        }

        const userId = topUserId || settings.userId
        if (!userId) return null

        const siteDetails = {
          ...siteProperties,
          userId,
          siteUrl,
          isSetupFinished: false,
          crawlJobIds: [],
        }
        const { data: siteId } = await fetch.put(`/sites/`, { siteDetails })
        if (siteProperties.isFirstSite) {
          dispatch(actions.fetchAllSites())
          history.push(`/app/dashboard`)
        } else {
          history.push(`/app/site/${siteId}`)
        }
      } catch (e) {
        logger.handleError(e)
      }
    },
  initiateSite: (siteId, siteProps) => async (dispatch, getState) => {
    try {
      const { data: jobId } = await fetch.put(`/sites/${siteId}`, { siteProps })
      dispatch(JobsActions.startJob(jobId))
    } catch (e) {
      logger.handleError(e)
    }
  },
  simpleCreateSite: (siteProps) => async (dispatch, getState) => {
    try {
      const { settings, sites } = getState()
      const allowedProjects =
        settings && settings.permissions && settings.permissions.allowedProjects
      const hasPurchased = settings && settings.permissions && settings.permissions.hasPurchased
      const canCreateProjects = allowedProjects
        ? allowedProjects > sites.sites.filter((s) => s.isSetupFinished).length
        : false

      if (!canCreateProjects) {
        dispatch(SettingsActions.notifyUserSiteLimit())
        return dispatch(ModalsActions.toggleUpgradeModal('projects'))
      }

      if (!settings.userId) return null

      const siteDetails = {
        userId: settings.userId,
        siteUrl: siteProps.siteUrl,
        isSetupFinished: true,
        crawlJobIds: [],
      }
      dispatch({ type: actionTypes.SIMPLE_CREATE_SITE_LOADING })
      const { data: siteId } = await fetch.put(`/sites/`, { siteDetails })

      const { data: jobId } = await fetch.put(`/sites/${siteId}`, { siteProps })
      if (!jobId) throw new Error('Not completed')
      dispatch(JobsActions.startJob(jobId, false))
      dispatch(actions.fetchAllSites())
      dispatch({ type: actionTypes.SIMPLE_CREATE_SITE_DONE })
    } catch (e) {
      dispatch({ type: actionTypes.SIMPLE_CREATE_SITE_ERROR })
      logger.handleError(e)
    }
  },
  editSite: (siteId, siteProps) => async (dispatch, getState) => {
    try {
      dispatch({ type: actionTypes.UPDATE_SITE_LOADING })
      await fetch.patch(`/sites/${siteId}`, { siteProps })
      dispatch({ type: actionTypes.UPDATE_SITE_DONE })
      await dispatch(actions.fetchAllSites())
      history.push(`/app/dashboard`)
      // this push needs to be very well tested
    } catch (e) {
      dispatch({ type: actionTypes.UPDATE_SITE_ERROR })
      logger.handleError(e)
    }
  },
  removeSite: (siteId) => async (dispatch, getState) => {
    try {
      if (!siteId) return

      dispatch({ type: actionTypes.FETCH_ALL_SITES_LOADING })

      await fetch.delete(`/sites/${siteId}`)
      dispatch(NotificationsActions.removeSiteNotifications(siteId))
      dispatch(actions.fetchAllSites())
      // dispatch(SettingsActions.getUserData());
    } catch (e) {
      dispatch({ type: actionTypes.UPDATE_SITE_ERROR })
      logger.handleError(e)
    }
  },
  fetchSingleSite: (siteId) => async (dispatch, getState) => {
    try {
      // const userId = getState().settings.userId;
      const { data: site } = await fetch.get(`/sites/${siteId}`)
      dispatch({ type: actionTypes.FETCH_SINGLE_SITE_DONE, siteId, site })
    } catch (e) {
      logger.handleError(e)
    }
  },
  fetchAllSites: () => async (dispatch, getState) => {
    try {
      const { userId } = getState().settings
      if (!userId) return null
      // console.log('firing with a userId', userId);

      dispatch({ type: actionTypes.FETCH_ALL_SITES_LOADING })

      const { data: sites } = await fetch.get(`/sites/`)
      let nextSites = []
      if (sites && Object.keys(sites).length > 0) {
        nextSites = Object.keys(sites).map((k) => ({
          ...sites[k],
          siteId: k,
        }))
      }
      dispatch({ type: actionTypes.FETCH_ALL_SITES_DONE, sites: nextSites })
    } catch (e) {
      logger.handleError(e)
    }
  },
  setSelectedSiteId: (siteId) => ({ type: actionTypes.SET_SELECTED_SITE_ID, siteId }),
  fetchSitemap: (siteId, sitemapUrl) => async (dispatch, getState) => {
    try {
      const { userId } = getState().settings
      if (!userId) return null
      // console.log('firing with a userId', userId);

      dispatch({ type: actionTypes.FETCH_SITEMAP_LOADING, siteId })
      const { data: sitemap } = await fetch.post(`/sites/${siteId}/sitemap/`, { sitemapUrl })
      console.log(sitemap)
      if (!sitemap) throw new Error('Failed to Get Sitemap')
      dispatch({ type: actionTypes.FETCH_SITEMAP_DONE, siteId, sitemap })
    } catch (e) {
      dispatch({ type: actionTypes.FETCH_SITEMAP_ERROR, siteId, sitemapError: e.message })
      logger.handleError(e)
    }
  },
}

export const getDefaultState = () => ({
  sites: [],
  loading: true,

  fetchSitesLoading: false,
  fetchSitesSuccess: false,
  fetchSitesError: false,

  updateLoading: false,
  updateSuccess: false,
  updateError: false,

  currentSiteId: null,

  simpleCreateSiteLoading: false,
  simpleCreateSiteError: false,
  simpleCreateSiteSuccess: false,
})

export default handleActions(
  {
    [actionTypes.FETCH_SINGLE_SITE_LOADING]: (state) => ({ ...state, loading: true }),
    [actionTypes.FETCH_SINGLE_SITE_DONE]: (state, { siteId, site }) => {
      const nextSites = state.sites.filter((s) => s.siteId !== siteId).concat({ ...site, siteId })
      return { ...state, sites: nextSites, loading: false }
    },

    [actionTypes.FETCH_ALL_SITES_LOADING]: (state) => ({
      ...state,
      loading: true,
      fetchSitesLoading: true,
      fetchSitesSuccess: false,
      fetchSitesError: false,
    }),
    [actionTypes.FETCH_ALL_SITES_DONE]: (state, { sites }) => ({
      ...state,
      sites: _.reverse(sites),
      loading: false,
      fetchSitesLoading: false,
      fetchSitesSuccess: true,
      fetchSitesError: false,
    }),

    [actionTypes.UPDATE_SITE_DONE]: (state) => ({
      ...state,
      updateLoading: false,
      updateError: false,
      updateSuccess: true,
    }),
    [actionTypes.UPDATE_SITE_LOADING]: (state) => ({
      ...state,
      updateLoading: true,
      updateError: false,
      updateSuccess: false,
    }),
    [actionTypes.UPDATE_SITE_ERROR]: (state) => ({
      ...state,
      updateLoading: false,
      updateError: true,
      updateSuccess: false,
    }),

    [actionTypes.SET_SELECTED_SITE_ID]: (state, selectedSiteId) => ({ ...state, selectedSiteId }),

    [actionTypes.FETCH_SITEMAP_LOADING]: (state, { siteId }) => {
      const nextSites = state.sites.map((s) => {
        if (s.siteId !== siteId) return s
        return {
          ...s,
          sitemap: [],
          sitemapLoading: true,
          sitemapError: false,
          sitemapSuccess: false,
        }
      })
      return { ...state, sites: nextSites }
    },
    [actionTypes.FETCH_SITEMAP_DONE]: (state, { siteId, sitemap }) => {
      const nextSites = state.sites.map((s) => {
        if (s.siteId !== siteId) return s
        return { ...s, sitemap, sitemapLoading: false, sitemapError: false, sitemapSuccess: true }
      })
      return { ...state, sites: nextSites }
    },
    [actionTypes.FETCH_SITEMAP_ERROR]: (state, { siteId, sitemapError = true }) => {
      const nextSites = state.sites.map((s) => {
        if (s.siteId !== siteId) return s
        return { ...s, sitemap: [], sitemapLoading: false, sitemapError, sitemapSuccess: false }
      })
      return { ...state, sites: nextSites }
    },
    [actionTypes.SIMPLE_CREATE_SITE_DONE]: (state) => ({
      ...state,
      simpleCreateSiteLoading: false,
      simpleCreateSiteError: false,
      simpleCreateSiteSuccess: true,
    }),
    [actionTypes.SIMPLE_CREATE_SITE_LOADING]: (state) => ({
      ...state,
      simpleCreateSiteLoading: true,
      simpleCreateSiteError: false,
      simpleCreateSiteSuccess: false,
    }),
    [actionTypes.SIMPLE_CREATE_SITE_ERROR]: (state) => ({
      ...state,
      simpleCreateSiteLoading: false,
      simpleCreateSiteError: true,
      simpleCreateSiteSuccess: false,
    }),
  },
  getDefaultState()
)
