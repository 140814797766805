export const getEnv = () => {
  const isProd = window.location.host.includes('app.amzwatcher.com')
  const isStaging = window.location.host.includes('staging.amzwatcher.com')
  const isDev =
    window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1')

  return {
    isProd,
    isStaging,
    isDev,
  }
}

export const getBaseUrl = () => {
  const env = getEnv()
  console.log('ENVIRONMENTS', env)
  if (env.isProd) return 'https://app.amzwatcher.com'
  if (env.isStaging) return 'https://staging.amzwatcher.com'
  return 'http://localhost:5000'
}

const STRIPE_TEST_KEY = 'pk_test_yyvlTgOIqN9kOECAxuKoKHec'
const STRIPE_LIVE_KEY = 'pk_live_l5WhPyz6mSVpBUr06BSYU2mK'

export const getStripeKey = () => {
  const env = getEnv()
  if (env.isProd) return STRIPE_LIVE_KEY
  if (env.isStaging) return STRIPE_TEST_KEY
  return STRIPE_TEST_KEY
}

export const getStripePlanKeys = (isYearly = false) => {
  const env = getEnv()
  if (env.isStaging || env.isDev) {
    // old plans, not updated
    if (isYearly) {
      return {
        hibernate: 'plan_GHht07Gv0k1eFC',
        starter: 'plan_FoYfJn1dLO8dzX',
        standard: 'plan_HAmtPshEUX1lpK',
        portfolio: 'plan_FoYf1VpHeonQpJ',
        tycoon: 'plan_FoYgxhmLJFVMhI',
        custom1: 'plan_H2FAn7yW8RrBAE',
      }
    } else {
      return {
        hibernate: 'plan_GHht07Gv0k1eFC',
        starter: 'plan_G20mZAi9DJYtiD',
        standard: 'plan_HAmtjQjE2PtmU6',
        portfolio: 'plan_G20mdzHZASwKgr',
        tycoon: 'plan_G20nNcppPx5Cyp',
      }
    }
  } else {
    if (isYearly) {
      return {
        hibernate: 'plan_GHhrMpSpjXePnC',
        starter: 'plan_GHKQPgHUB9CDrO',
        standard: 'price_1GxPo3D8Z4CRurqsHBHaEdee',
        portfolio: 'plan_GHKRj0xoPVK0mW',
        tycoon: 'plan_GHKS7OjGdHFsXC',
        custom1: 'plan_H3hCjXUe5RbkMK',
        custom2: 'price_1IRZ23D8Z4CRurqsO1tAngeB',
        custom3: 'price_1IgAWRD8Z4CRurqs64GcqCbf',
        custom4: 'price_1N3C9PD8Z4CRurqsk974ShJs',
      }
    } else {
      return {
        hibernate: 'plan_GHhrMpSpjXePnC',
        starter: 'plan_G0e23PWQHVG7Ow',
        standard: 'plan_HAmse4Ul760utM',
        portfolio: 'plan_G0eCS3IDm1suIt',
        tycoon: 'plan_G0e4pyQ4h54Aow',
      }
    }
  }
}

export const getPaypalPlanKeys = (isYearly = false) => {
  const env = getEnv()
  if (isYearly) {
    if (env.isStaging || env.isDev) {
      return {
        starter: 'QK7AANMZH99EQ',
        portfolio: 'QK7AANMZH99EQ',
        tycoon: 'QK7AANMZH99EQ',
      }
    }
    return {
      starter: 'MTDUD4DAR8C62',
      portfolio: 'QEEBBRNRTZQRU',
      tycoon: 'FTK2TNRJJTD2A',
    }
  }
  if (env.isStaging || env.isDev) {
    return {
      starter: 'QK7AANMZH99EQ',
      portfolio: 'QK7AANMZH99EQ',
      tycoon: 'QK7AANMZH99EQ',
    }
  }
  return {
    starter: 'VRAJLGMEFUFS2',
    portfolio: 'FNJ5KTEDBTUSJ',
    tycoon: '76324UC5DFP2J',
  }
}
